<template>
  <no-ssr>
    <div class="admin-navigation">
      <!-- --- ONLY ON MOBILE VIEW --- -->
      <div class="w-100 wrapp-row d-flex d-md-none">
        <div class="user-name">
          <span>{{$t("Hello") + ' ' + userName}}</span>
        </div>
        <div class="logout-cta">
          <button type="button" class="btn btn-none bcm-link" @click.prevent="logout">
            <span v-t="'logout'"></span>
          </button>
        </div>
      </div>
      <!-- --------------------------- -->
      <div class="user-name d-none d-md-block">
        <span>{{$t("Hello") + ' ' + userName}}</span>
      </div>
      <div class="wrapp-link-list">
        <ul class="link-list">
          <b-nav-item v-for="link in links" :key="link.key" class="link-list__item" :to="link.to" link-classes="link-list__item__cta bcm-link">
            <span v-t="link.key"></span>
            <div class="line-indicator"></div>
          </b-nav-item>
        </ul>
      </div>
      <div class="logout-cta d-none d-md-block">
        <button type="button" class="btn btn-none bcm-link" @click.prevent="logout">
          <span v-t="'logout'"></span>
        </button>
      </div>
    </div>
  </no-ssr>
</template>

<script>
export default {
  data: () => ({
    activeSlug: '',
    links: [
      {
        key: 'route.my-profile',
        to: { name: 'myProfile' }
      },
      {
        key: 'route.my-addresses',
        to: { name: 'myAddresses' }
      },
      {
        key: 'route.my-orders',
        to: { name: 'myOrders' }
      },
      {
        key: 'route.my-wishlist',
        to: { name: 'myWishList' }
      },
      {
        key: 'route.my-newsletter',
        to: { name: 'myNewsletter' }
      },
      {
        key: 'route.my-returns',
        to: { name: 'myReturns' }
      },
      {
        key: 'route.my-cards',
        to: { name: 'myCards' }
      },
      {
        key: 'route.my-tickets',
        to: { name: 'myTickets' }
      }
    ]
  }),
  props: {
    userName: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    logout () {
      this.$router.push({ name: 'home' })
      this.$store.dispatch('user/logout')
      location.reload()
    }
  }
}
</script>
<style lang="scss" scoped>
ul{
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    margin-bottom: 15px;

    .bcm-link-primary {
      &::after {
        width: 0;
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }

      &.active,
      &.router-link-active {
        &::after {
          width: 100%;
        }
      }
    }
  }
}
</style>
